import React from 'react';
import './styles/PoliciesPage.css'; // Import the CSS file for styling

const PoliciesPage = () => (
  <section className="policy-page" id="policy-page">
    <div className="container">
      <h2>Welcome to OGA INTRADE</h2>
      <p>Please read the following Terms and Conditions carefully before enrolling in our programs. By participating in our programs, you agree to these terms.</p>
      
      <h3>Acceptance of Terms</h3>
      <p>By enrolling in any of our programs, you agree to abide by our Privacy Policy, Terms of Use, and Refund Policy. These terms govern your participation in our programs and any disputes that may arise.</p>
      
      <h3>Eligibility</h3>
      <p>Participants must provide accurate and complete information during the enrollment process. OGA INTRADE reserves the right to verify the information provided and to cancel any enrollment that does not meet our criteria.</p>
      
      <h3>Refund Policy</h3>
      <ul>
        <li>Send a cancellation request via email to <a href="mailto:refund@laana.com">refund@laana.com</a>.</li>
        <li>Ensure the request is received at least 10 days prior to the program start date.</li>
      </ul>
      <p>Refunds or duplicate payments will be processed within 10-12 working days from the date of the cancellation request. Requests failing to meet these conditions will not be accepted, and no refund will be provided.</p>
      
      <h3>Payment Terms</h3>
      <p>All program fees must be paid in full before the start of the program. Any additional costs, such as trip expenses, are the responsibility of the participant and will be billed separately.</p>
      
      <h3>Changes to Programs</h3>
      <p>OGA INTRADE reserves the right to cancel or reschedule programs as necessary. In such cases, affected participants will be notified as soon as possible and offered an alternative date or a full refund.</p>
      
      <h3>Participant Conduct</h3>
      <p>Participants are expected to conduct themselves in a respectful and professional manner. Disruptive or inappropriate behavior may result in removal from the program without a refund.</p>
      
      <h3>Liability</h3>
      <p>OGA INTRADE is not responsible for any indirect, incidental, or consequential damages arising from your participation in our programs. Our total liability for any claims is limited to the amount you paid for the program.</p>
      
      <h3>Use of Materials</h3>
      <p>All materials provided during the program are for personal use only. Participants may not reproduce, distribute, or share program materials without prior written consent from OGA INTRADE.</p>
      
      <h3>Privacy</h3>
      <p>Your personal information will be used in accordance with our Privacy Policy. We are committed to protecting your privacy and will not share your information with third parties without your consent, except as required by law.</p>
      
      <h3>Changes to Terms</h3>
      <p>OGA INTRADE reserves the right to revise these Terms and Conditions at any time without prior notice. Any changes will be effective immediately upon posting on our website. Continued participation in our programs constitutes acceptance of any such changes.</p>
      
      <h3>Governing Law</h3>
      <p>These Terms and Conditions are governed by the laws of India. Any disputes arising from these terms or your participation in our programs will be subject to the exclusive jurisdiction of the courts in India.</p>
      
      <h3>Contact Information</h3>
      <p>For any questions or concerns regarding these Terms and Conditions, please contact us at <a href="mailto:info@laana.com">info@laana.com</a>.</p>
      
      <p><em>Last Updated on 10th April 2024</em></p>
    </div>
  </section>
);

export default PoliciesPage;
