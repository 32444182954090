import React, { useEffect, useState } from 'react';
import { Element } from 'react-scroll';
import HeroSection from './HeroSection';
import WhoWeAreForSection from './WhoWeAreForSection';
import WhoWeAreSection from './WhoWeAreSection';
import ProjectsSection from './ProjectsSection';
import TestimonialSection from './TestimonialSection';
import CommunitySection from './CommunitySection';
import CTASection from './CTASection';
import Footer from './Footer';
import Sidebar from './Sidebar';
import Flagship from './Flagship';
import Whyus from './Whyus';
import OurApproach from './OurApproach';
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Link } from 'react-router-dom';

const Main = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [open, setOpen] = useState(false); // State for dialog visibility

  const openNav = () => {
    setSidebarOpen(true);
  };

  const closeNav = () => {
    setSidebarOpen(false);
  };
  const handleOnClick = (id) => {
    setSidebarOpen(id);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(true);
    }, 5000); // 5000 milliseconds = 5 seconds
    return () => clearTimeout(timer);
  }, []); 

  const handleClose = () => {
    setOpen(false); 
  };
  const redirectToExternalURL = () => {
    window.open('https://docs.google.com/document/d/1AXlkWigjkeHAVcD0z1W1JwARXenib-CsLaQjFOY87V0/pub', '_blank', 'noopener,noreferrer');
  };
  return (
    <>
     <Dialog onClose={handleClose} open={open}>
        <DialogContent className='dialog_box'>
        <div className="project-card" style={{cursor:'pointer'}} onClick={redirectToExternalURL}>
        <div className="project-image project-image4"></div>
          <div className='project_flex'>
          <div className="project-details" style={{textAlign:'center'}}>
          <h3>
            GreenWave 
          </h3>
          <p>Project Dates: Program Starting From October 9th,2024</p>
          <p>Dive into the future of Smart Cities, Sustainable Fashion, and Waste Management, gaining the skills to lead in tomorrow's green industries.</p>
         
        </div>
       <div>
       {/* <a href="/https://docs.google.com/forms/d/e/1FAIpQLSd_x_8G70lDx3LDpRFD2nKr69Lw1O-0F6ls0rFA98t44fQEDQ/viewform">
            <button className="view-details-button">Open</button>
          </a> */}
          {/* <button className="view-details-button" style={{background:'green'}} onClick={redirectToExternalURL}>Open</button> */}
          {/* <Link className="view-details-button" style={{background:'green', textDecoration:'none'}}  to={'/greenwaveRegistration'}>Open</Link> */}
       <p style={{color: 'green', textAlign:'center',marginBottom:'8px'}}><strong>Registration Open</strong></p>
       <p style={{color: 'red', textAlign:'center', fontSize:'14px'}}><strong>Click here to register</strong></p>
       </div>
</div>
      </div>
        </DialogContent>
        {/* <Button onClick={handleClose} color="primary">Close</Button> */}
      </Dialog>
      <Sidebar openNav={openNav} isSidebarOpen={isSidebarOpen} closeNav={closeNav} handleOnClick={handleOnClick} />
      <Element name="hero-section">
        <HeroSection />
      </Element>
      <Element name="who-we-are-for">
        <WhoWeAreForSection />
      </Element>
      <Element name="who-we-are">
        <WhoWeAreSection />
      </Element>
      <Element name="projects">
        <ProjectsSection />
      </Element>
      {/* <Element name="testimonials">
        <TestimonialSection />
      </Element> */}
      <Element name="community">
        <CommunitySection />
      </Element>
      <Element name="flagship">
        <Flagship />
      </Element>
      <Element name="whyus">
        <Whyus />
      </Element>
      <Element name="approach">
       <OurApproach />
      </Element>
      <Element name="cta">
        <CTASection />
      </Element>
      <Footer />
    </>
  );
};

export default Main;
