import HomeButton from "./HomeButton";

const Greenwaveregistration = () =>{
    const redirectToExternalURL = () => {
        window.open('https://docs.google.com/forms/d/e/1FAIpQLSd_x_8G70lDx3LDpRFD2nKr69Lw1O-0F6ls0rFA98t44fQEDQ/viewform', '_blank');
      };
    return(
        <div className="greenwave">
                <HomeButton />
           <div className="greenwave_inn">
             <div className="img_box"></div>
             <p className="text-center">LAANA <br/>
            Greenwave - An offline program.</p>
            <p>Our <strong>Sustainable Innovation Program</strong> offers a dynamic and hands-on journey into the future of Smart Cities and Sustainable Fashion, empowering you to lead in industries that are redefining the world. Designed to combine theory with real-world application, this program equips you with the skills and insights needed to drive impactful change.</p>
            <h5 className="font-bold">Program Highlights:</h5>
            <ul>
                <li><strong>Masterclasses</strong> on Smart Cities, Urban Policy and Sustainable Fashion led by industry experts, providing a deep dive into sustainability at both the community and global level.</li>
                <li><strong>Field Projects and Site Visits</strong>: Engage in live projects where you'll interact with key stakeholders such as city officials, urban planners, and community leaders, clothing manufacturers, fashion brand executives , gaining first-hand experience in solving real-world challenges.</li>
                <li><strong>Sustainable Fashion</strong>: Explore the future of fashion with a focus on ethical production, circular economies, and eco-conscious design, preparing you to innovate in one of the fastest-growing sectors.</li>
            </ul>
            <h5 className="font-bold">Meet Your Mentors:</h5>
            <ul>
                <li><strong>Ms. Susmita:</strong>An alumnus of IIT Delhi and TU Munich, Susmita is a Smart City Consultant, entrepreneur, and Urban Policy lecturer. Her expertise and global experience provide invaluable guidance throughout the program.</li>
                <li><strong>Ms. Anshu Bhogra</strong>: CBO of <strong>Forever New</strong>, Anshu brings a wealth of knowledge in sustainable fashion and corporate strategy, offering students an insider perspective into the fashion industry's transition to sustainability.</li>
                <li><strong>Sustainable Fashion</strong>: Explore the future of fashion with a focus on ethical production, circular economies, and eco-conscious design, preparing you to innovate in one of the fastest-growing sectors.</li>
            </ul>
            <h5 className="font-bold">Awards and Recognition:</h5>
            <p>Upon completing the program, all participants will receive certificates signed by our experts and partnered organizations. The top-performing students will also receive a Letter of Recommendation from our mentors which is a  prestigious endorsement to elevate your career.

Whether you're passionate about transforming cities or reshaping the fashion industry, this program is your stepping stone to becoming a leader in sustainability. Don't miss this opportunity to align your career with the global movement for a better, greener future!</p>
<p>October Program dates are as below: <strong>Dates are planned as per schools schedule.</strong></p>
<p>Location - Gurgaon</p> <br /><br />

<p>9th October - 13th October</p>
<p>19th October - 20th October</p>
<p>26th October -30th October</p>
<p style={{color:'#5fc0fa'}}>For enrolling into the program please register on below link:</p>
<p onClick={redirectToExternalURL} style={{color:'blue', cursor:'pointer'}}>https://docs.google.com/forms/d/e/1FAIpQLSd_x_8G70lDx3LDpRFD2nKr69Lw1O-0F6ls0rFA98t44fQEDQ/viewform</p>
<p>Enrollment is subject to submission of registration form and registration fee. Last date to apply is 5th Of October. </p>
<p>First 3 applicants will receive a fee waiver of 50%. </p>
<p>Maximum students in one batch is 7.</p> <br />
<p>Payment details are as below  :</p><br />

<p>Bank Details - Bank- HDFC <br />
Account Name : Oga Intrade <br />
Account No : 50200049486812 <br />
Account Type- Current <br />
IFSC - HDFC0000485 <br />
</p>
<p>Total - 25,000 inclusive of 18% GST</p>
<p>For any queries please write to us at <a href="mailto:itslaanaa@gmail.com">itslaanaa@gmail.com</a></p>
           </div>
        </div>
    )
}

export default Greenwaveregistration