// CommunitySection.jsx
import React from 'react';
import './styles/CommunitySection.css'
const OurApproach = () => (
  <section className="community" id="ourapproach">
    <div className="container">
    <div className='community_imgdv'>
      <div className='approach_img'>
      </div>
  <div className="imgdv_content approach_dv">
  <h2 className="comun_head aproach_head">Our <br /> Approach</h2>
    <p >Our programs are meticulously crafted to deliver tangible and lasting results, ensuring that participants not only acquire new knowledge and skills but also undergo significant personal growth. Through a blend of practical training, hands-on activities, and reflective exercises, individuals gain a deeper understanding of themselves and their capabilities. This comprehensive approach fosters increased confidence as participants tackle real-world challenges and achieve measurable success. Moreover, our emphasis on self-awareness helps individuals recognize their strengths and areas for development, empowering them to take control of their personal and professional lives. Ultimately, they leave our programs with a renewed sense of empowerment, equipped with the tools and mindset to make meaningful contributions in their communities and beyond. </p>
  </div>
    </div>
    </div>
    <div className="logo-container">
    </div>
  </section>
);

export default OurApproach;