
import Main from './components/Main';
import Project1 from './components/projects/Project1';
import Project2 from './components/projects/Project2';
import Project3 from './components/projects/Project3';
import Project4 from './components/projects/Project4';
import Project5 from './components/projects/Project5';
import Project6 from './components/projects/Project6';
import AboutUs from './components/AboutUs';
import GetInTouch from './components/GetInTouch';
import RefundPolicyPage from './components/RefundPolicy';
import PoliciesPage from './components/Policy';
import React from 'react';
import SubmittedPageSuccess from './components/projects/SubmittedPageSuccess';
import './styles.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SubmittedPageFailure from './components/projects/SubmittedPageFailure';
import Greenwaveregistration from './components/GreenwaveRegistration';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/about" element={<AboutUs/>} />
        <Route path="/project1" element={<Project1/>} />
        <Route path="/project2" element={<Project2/>} />
        <Route path="/project3" element={<Project3/>} />
        <Route path="/project4" element={<Project4/>} />
        <Route path="/project5" element={<Project5/>} />
        <Route path="/project6" element={<Project6/>} />
        <Route path="/GetInTouch" element={<GetInTouch/>} />
        <Route path="/RefundPolicy" element={<RefundPolicyPage/>} />
        <Route path="/Policy" element={<PoliciesPage/>} />
        <Route path="/GreenwaveRegistration" element={<Greenwaveregistration/>} />
        <Route path="/submitSuccess" element={<SubmittedPageSuccess/>} />
        <Route path="/submitFailure" element={<SubmittedPageFailure/>} />

      </Routes>
    </Router>
  );
}




// function App() {
//   return (
//     <div>
//       <Main/>

//     </div>
//   );
// }

export default App;
