import React from 'react';

const SubmittedPageFailure = () => {
  return (
    <div className="submitted-page-failure-container">
      <h2 className="failure-heading">Application Failed to Submit</h2>
      <p className="failure-message">
        Please fix the application form and try again. Contact +91 9810044240 for any information.
      </p>
      {/* Add any additional content for the submitted page */}
    </div>
  );
};

export default SubmittedPageFailure;
