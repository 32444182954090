// HeroSection.jsx
import React from 'react';
import { Link as ScrollLink } from 'react-scroll';
import './styles/HeroSection.css'
import { Box } from '@mui/material';
import { SOCIAL_LINKS } from './const';
import { Link } from 'react-router-dom';
import Marquee from 'react-fast-marquee';
function scrollToProjects(){
  <ScrollLink to="projects" smooth={true} duration={500}> </ScrollLink>
}



const HeroSection = () => {
  const redirectToExternalURL = () => {
    window.location.href = 'https://docs.google.com/document/d/1AXlkWigjkeHAVcD0z1W1JwARXenib-CsLaQjFOY87V0/pub';
  };

  // const redirectToExternalURLTo = () => {
  //   window.location.href = 'https://docs.google.com/document/d/1AXlkWigjkeHAVcD0z1W1JwARXenib-CsLaQjFOY87V0/pub';
  // };
  return(
    <div className="hero-container">
    <div className='link_col'>
      
     <span style={{color: '#FF0000'}}><strong>Registration open</strong></span>
      <button className='hr_btn' onClick={redirectToExternalURL}>Click here</button>
     <span style={{color:'#ff0000'}}><strong>to register</strong></span>

    </div>
    <div className='banner_logo'></div>
          {/* <h1 className={'lana_ttl'}>Laanaa</h1> */}
          <h1 className="animated-heading">Laanaa</h1>

    <section className="hero-section hero_icon" id="hero-section">

    <Box className="heroiconbutn" sx={{display:'flex', columnGap:'2px', flexDirection:'column'}}>
        {
          SOCIAL_LINKS.map((items)=>{
            return(
              <>
                <Link to={`${items.link}`} target='_blank'>{items.icon}</Link>
              </>
            )
          })
        }
        
      </Box>
      <div className="container">
        {/* <p className="animated-subheading">Join us in making a positive impact on the world!</p> */}
       
      </div>
    </section>
  </div>
  )
};

export default HeroSection;
