import React from 'react';
import HomeButton from '../HomeButton';
import './Project.css'; // Make sure this file contains all required styles
import ApplicationForm from './ApplicationForm'; // Import the ApplicationForm component

const Project3 = () => {
    return (
        <>
            <HomeButton />
            <div className="project3-bigpicture"> </div>
            <div className="project-container">
                <div className="project-details-cards">
                    <h1>Bright Minds: A Holistic Learning Program for Helping Underprivileged School Children in India</h1>
                    <p>Date: TBD</p>
                    <p>Duration: 10 days</p>
                    <p>Batch Size: 5-10 students</p>
                    <p>Cost: 20000 Rupees</p>
                    <p>Location: Gurgaon</p>
                    <p>Details: Join the Bright Minds program, dedicated to empowering underprivileged school children in India. Experience a fulfilling 15-day journey where you'll contribute to their education, covering various subjects through a holistic approach. This volunteering opportunity promises skill development, leadership experiences, and the chance to make a real difference.</p>
                    <p>Learning Outcomes: Elevate your capabilities with skills in leadership, communication, strategic planning, and more, preparing you for future challenges while making a tangible impact. <strong>Certificates and Letters of Recomendations provided to all participants who have successfully completed the program</strong></p>
                    <p>Knowledge Partners: TBD</p>
                    <br></br>
                    
<div class="program-audiences">
<h2>Who Should Do This Program:</h2>
<br></br>
    <div class="audience-category">
        <h4>1. Students:</h4>
        <ul>
            <li>Aspiring for careers in education, public policy, social sector, politics, or administrative services.</li>
            <li>Seeking to build a robust profile for studies abroad or future career opportunities.</li>
            <li>Interested in integrating social work into their summer vacation or as part of their curriculum.</li>
            <li>Wishing to make meaningful use of their summer vacation by contributing to a cause and learning new skills.</li>
        </ul>
    </div>
    <div class="audience-category">
        <h4>2. Educators and Mentors:</h4>
        <ul>
            <li>Looking to broaden teaching skills beyond the traditional classroom settings.</li>
            <li>Eager to engage with and impact students from diverse backgrounds and environments.</li>
            <li>Interested in developing new teaching methodologies and contributing to societal change.</li>
        </ul>
    </div>
    <div class="audience-category">
        <h4>3. Professionals Seeking Personal Growth:</h4>
        <ul>
            <li>Aiming to enhance leadership, communication, and interpersonal skills.</li>
            <li>Looking to contribute to a meaningful cause while undergoing personal development.</li>
            <li>Interested in exploring new areas of growth outside the conventional professional environment.</li>
        </ul>
    </div>
    <div class="audience-category">
        <h4>4. Corporate Teams Interested in CSR Initiatives:</h4>
        <ul>
            <li>Companies and organizations aiming to engage their employees in meaningful CSR activities.</li>
            <li>Teams looking for team-building exercises that offer tangible impact and community engagement.</li>
            <li>Corporate groups wanting to enhance their public image and contribute positively to society.</li>
        </ul>
    </div>
</div>

                    <h2>Delivery Method:</h2>
                    <ul>
                        <li>Daily interactive sessions with teachers and facilitators.</li>
                        <li>Hands-on activities and group projects.</li>
                        <li>Collaborative projects and group discussions.</li>
                        <li>Outdoor activities for comprehensive learning.</li>
                    </ul>

                    <h2>Evaluation:</h2>
                    <p>Participants will be assessed through active participation, projects, and a final showcase of their learnings.</p>

                    <h2>Certification:</h2>
                    <p>Participants who actively engage in the program will receive a certificate of completion and a Letter of recommendation to recognize their efforts.</p>

                    {/* Application Form */}
                    <ApplicationForm projectName="Bright Minds" />
                </div>
            </div>
        </>
    );
};

export default Project3;

