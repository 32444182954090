import React, { useState } from 'react';
import { FaInstagram, FaRedditAlien, FaFacebookF, FaLinkedinIn } from 'react-icons/fa'; // Ensure you have react-icons installed
import './styles/GetInTouch.css'
function GetInTouch() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(formData);
        alert('Thank you for getting in touch!');
    };

    return (
        <div className="contact-section">
            <h2>Get in Touch</h2>
            <form onSubmit={handleSubmit} className="contact-form">
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <textarea id="message" name="message" value={formData.message} onChange={handleChange} required></textarea>
                </div>
                <button type="submit">Submit</button>
            </form>
            <div className="social-links">
                <a href="https://www.instagram.com/laanaa_officially/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
                <a href="https://www.reddit.com/user/itslaanaa/" target="_blank" rel="noopener noreferrer"><FaRedditAlien /></a>
                {/* <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer"><FaFacebookF /></a> */}
                <a href="https://www.linkedin.com/company/laanaa" target="_blank" rel="noopener noreferrer"><FaLinkedinIn /></a>
            </div>
        </div>
    );
}

export default GetInTouch;
