import React from 'react';
import './styles/CTASection.css'; // Import the CSS file for styling
import { Link } from 'react-router-dom';

const CTASection = () => (
  <section className="cta-section" id="cta-section">
    <div className="container">
      <h2>Ready to Make a Difference?</h2>
      <p>Join us today and be a part of positive change!</p>
      <div className="button-group">
        <Link to="/GetInTouch">
          <button className="btn btn-warning btn-lg">Get In Touch</button>
        </Link>
        <Link to="/RefundPolicy">
          <button className="btn btn-secondary btn-lg">Refund Policy</button>
        </Link>
        <Link to="/Policy">
          <button className="btn btn-secondary btn-lg">Policies</button>
        </Link>

        {/* <Link to="/TermsAndConditions">
          <button className="btn btn-secondary btn-lg">Terms and Conditions</button>
        </Link>
        <Link to="/PrivacyPolicy">
          <button className="btn btn-secondary btn-lg">Privacy Policy</button>
        </Link>
        <Link to="/HelpAndSupport">
          <button className="btn btn-secondary btn-lg">Help and Support</button>
        </Link>
        <Link to="/Disclaimer">
          <button className="btn btn-secondary btn-lg">Disclaimer</button>
        </Link> */}
      </div>
    </div>
  </section>
);

export default CTASection;
