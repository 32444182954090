import React from 'react';
import HomeButton from '../HomeButton';
import './Project.css'; // Import the styles
import ApplicationForm from './ApplicationForm'; // Import the ApplicationForm component

const Project5 = () => {
  
  return (
    <>
      <HomeButton/>
      <div className="project5-bigpicture"> </div>
      <div className="project-container">
        
        <div className="project-details-cards">
          <h1>GreenWave: An Outreach Program for Volunteer-driven Skilling Awareness in India</h1>
          <p>Date: TBD</p>
          <p>Details: SkillSprint endeavors to foster awareness regarding skill development initiatives in India via a 7-day outreach program. Volunteers will actively connect with communities, stressing the significance of skill enhancement and advocating available opportunities for personal and professional advancement. Participants will contribute to arranging workshops for local communities and spearhead a digital outreach initiative. They will also collaborate with social media experts to formulate an effective social media strategy for the digital outreach program. </p>
          <p>Duration: 7 days</p>
          <p>Learning Outcomes: <strong>Certificates and Letters of Recomendations provided to all participants who have successfully completed the program</strong></p>
          <p>Knowledge Partners: TBD</p>
          <p>Batch Size: 5-10 students</p>
          <p>Cost: 10000 Rupees</p>
          <p>Location: Delhi NCR</p>
                      
<div class="program-audiences">
<h2>Who Should Do This Program:</h2>
<br></br>
    <div class="audience-category">
        <h4>1. Students:</h4>
        <ul>
            <li>Aspiring for careers in education, public policy, social sector, politics, or administrative services.</li>
            <li>Seeking to build a robust profile for studies abroad or future career opportunities.</li>
            <li>Interested in integrating social work into their summer vacation or as part of their curriculum.</li>
            <li>Wishing to make meaningful use of their summer vacation by contributing to a cause and learning new skills.</li>
        </ul>
    </div>
    <div class="audience-category">
        <h4>2. Educators and Mentors:</h4>
        <ul>
            <li>Looking to broaden teaching skills beyond the traditional classroom settings.</li>
            <li>Eager to engage with and impact students from diverse backgrounds and environments.</li>
            <li>Interested in developing new teaching methodologies and contributing to societal change.</li>
        </ul>
    </div>
    <div class="audience-category">
        <h4>3. Professionals Seeking Personal Growth:</h4>
        <ul>
            <li>Aiming to enhance leadership, communication, and interpersonal skills.</li>
            <li>Looking to contribute to a meaningful cause while undergoing personal development.</li>
            <li>Interested in exploring new areas of growth outside the conventional professional environment.</li>
        </ul>
    </div>
    <div class="audience-category">
        <h4>4. Corporate Teams Interested in CSR Initiatives:</h4>
        <ul>
            <li>Companies and organizations aiming to engage their employees in meaningful CSR activities.</li>
            <li>Teams looking for team-building exercises that offer tangible impact and community engagement.</li>
            <li>Corporate groups wanting to enhance their public image and contribute positively to society.</li>
        </ul>
    </div>
</div>

                    <h2>Delivery Method:</h2>
                    <ul>
                        <li>Daily interactive sessions with teachers and facilitators.</li>
                        <li>Hands-on activities and group projects.</li>
                        <li>Collaborative projects and group discussions.</li>
                        <li>Outdoor activities for comprehensive learning.</li>
                    </ul>

                    <h2>Evaluation:</h2>
                    <p>Participants will be assessed through active participation, projects, and a final showcase of their learnings.</p>

                    <h2>Certification:</h2>
                    <p>Participants who actively engage in the program will receive a certificate of completion and a Letter of recommendation to recognize their efforts.</p>

                    {/* Application Form */}
                    
          {/* Week Details Table */}
          <h2>Program Breakdown</h2>
          <table>
            <thead>
              <tr>
                <th>Day</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Day 1 </td>
                <td> Orientation and Training of volunteers <p>Overview of skilling initiatives in India</p><p>Training on effective communication and outreach strategies</p></td>
              </tr>
              <tr>
                <td>Day 2-3</td>
                <td> Community Engagement <p> Door-to-door visits to introduce skilling programs </p> <p>Conducting informal surveys to understand local needs and interests</p>  </td>
              </tr>
              <tr>
                <td>Day 4</td>
                <td> Workshop in Local Communities <p> Organizing a workshop to showcase the benefits of skill development </p> <p> Interactive sessions on available skilling opportunities </p> </td>
              </tr>
              <tr>
                <td>Day 5</td>
                <td> Collaboration with Local Institutions <p> Visiting schools, community centers, and local organizations </p> <p> Presenting collaborative opportunities for skilling partnerships </p>  </td>
              </tr> 
              <tr>
                <td>Day 6</td>
                <td> Digital Outreach <p> Creating social media campaigns to reach a wider audience </p> <p> Engaging online communities through webinars or live sessions </p></td>
              </tr>
              <tr>
                <td>Day 7</td>
                <td> Culmination Event and Future Steps <p> Hosting a community event to celebrate the week's efforts </p> <p> Providing information on how individuals can enroll in skilling programs </p></td>
              </tr>
             

            </tbody>
          </table>
        </div>
        
        {/* Application Form */}
        <ApplicationForm projectName="Skill Sprint"/>
      </div>
    </>
  );
};

export default Project5;
