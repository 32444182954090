import React from 'react';
import HomeButton from '../HomeButton';
import './Project.css'; // Import the styles
import ApplicationForm from './ApplicationForm'; // Import the ApplicationForm component


const Project6 = () => {
    return (
        <>
            <HomeButton />
            <div className="project3-bigpicture"> </div>
            <div className="project-container">
                <div className="project-details-cards">
                    <h1>Cultural Canvas: A 2-Week Art and Culture Immersion Program</h1>
                    <p>Date: 12th June - 30th June</p>
                    <p>Duration: 10 days</p>
                    <p>Batch Size: 5-10 students</p>
                    <p>Cost: 20,000 Rupees + trip costs (on actuals)</p>
                    <p>Location: Gurgaon</p>
                    <p>Details: Cultural Canvas is a two-week experiential learning program designed to provide participants with a rich and immersive experience in India's art and culture. This program combines heritage walks, visits to art museums, and meaningful engagement with local artisans within the community to foster a deeper understanding and appreciation of India's diverse cultural heritage.</p>
                    <p>Learning Outcomes: Elevate your capabilities with skills in communication, strategic planning, collaboration, and leadership, preparing you for future challenges while making a tangible impact. <strong>Certificates and Letters of Recomendations provided to all participants who have successfully completed the program</strong></p>
                    <br></br>

                    <div class="program-audiences">
                        <h2>Who Should Do This Program:</h2>
                        <br></br>
                        <div class="audience-category">
                            <h4>1. Students:</h4>
                            <ul>
                                <li>Having an interest in Art & Culture.</li>
                                <li>Planning to pursue studies abroad and seeking to build a profile.</li>
                                <li>Incorporating social work into their summer vacation curriculum.</li>
                                <li>Wanting to take up social work during their summer vacation as part of their curriculum.</li>
                            </ul>
                        </div>
                        <div class="audience-category">
                            <h4>2. Educators and Mentors:</h4>
                            <ul>
                                <li>Seeking to broaden their teaching skills beyond the traditional classroom settings.</li>
                                <li>Eager to engage with the community from diverse backgrounds.</li>
                            </ul>
                        </div>
                        <div class="audience-category">
                            <h4>3. Professionals Seeking Personal Growth:</h4>
                            <ul>
                                <li>Aiming to develop leadership, communication, and interpersonal skills while contributing to a meaningful cause.</li>
                            </ul>
                        </div>
                        <div class="audience-category">
                            <h4>4. Corporate Teams Interested in CSR Initiatives:</h4>
                            <ul>
                                <li>Companies and organizations looking to engage their employees in corporate social responsibility (CSR) activities and team-building exercises that have a tangible impact on society.</li>
                            </ul>
                        </div>
                    </div>

                    <h2>Delivery Method:</h2>
                    <ul>
                        <li>Daily interactive sessions with teachers and facilitators.</li>
                        <li>Hands-on activities and group projects.</li>
                        <li>Collaborative learning through games and discussions.</li>
                        <li>Outdoor activities for a holistic learning experience.</li>
                    </ul>

                    <h2>Evaluation:</h2>
                    <p>Participants will be assessed through participation in daily activities, collaborative projects, and a showcase of their learning during the final celebration.</p>

                    <h2>Certification:</h2>
                    <p>Participants who actively engage in the program will receive a certificate of completion and a Letter of recommendation to recognize their efforts.</p>

                    {/* Application Form */}
                    <ApplicationForm projectName="Cultural Canvas" />
                </div>
            </div>
        </>
    );
};


export default Project6;
