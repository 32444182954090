import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';


const ApplicationForm = ({ projectName }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    school: '',
    reason: '',
    skills: '',
    qualification: '',
    comments: '',
    project: projectName,
  });

  const [invalidFields, setInvalidFields] = useState([]);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check for incomplete fields
    const invalidFieldsList = Object.keys(formData).filter(
      (key) => formData[key] === '' && e.target[key].hasAttribute('required')
    );

    // Highlight incomplete fields
    setInvalidFields(invalidFieldsList);

    if (invalidFieldsList.length > 0) {
      // If there are incomplete fields, do not submit the form
      return;
    }

    try {
      const response = await fetch('http://localhost:4000/submit-application', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        console.log('Form data submitted successfully!');
        // Navigate to the submitted page upon successful form submission
        navigate('/submitSuccess');
      } else {
        console.error('Failed to submit form data');
        navigate('/submitFailure');
      }
    } catch (error) {
      console.error('Error submitting form data:', error);
      navigate('/submitFailure');
    }
  };

  return (
    <div className="application-form">
      <h2>Apply for {projectName}</h2>
      <form onSubmit={handleSubmit}>
        <label className={invalidFields.includes('name') ? 'invalid-field' : ''}>
          Name:
          <input type="text" name="name" value={formData.name} onChange={handleChange} required />
        </label>
        <label className={invalidFields.includes('email') ? 'invalid-field' : ''}>
          Email:
          <input type="text" name="email" value={formData.email} onChange={handleChange} required />
        </label>
        <label className={invalidFields.includes('email') ? 'invalid-field' : ''}>
          Your School/College/Other
          <input type="text" name="school" value={formData.school} onChange={handleChange} required />
        </label>
        <label className={invalidFields.includes('reason') ? 'invalid-field' : ''}>
          Why do you want to join this program?
          <textarea
            name="reason"
            value={formData.reason}
            rows="4"
            onChange={handleChange}
            required
          />
        </label>

        <label className={invalidFields.includes('skills') ? 'invalid-field' : ''}>
           What skills do you bring to the program?
          <textarea
            name="qualification"
            value={formData.skills}
            rows="4"
            onChange={handleChange}
            required
          />
        </label>
        <label className={invalidFields.includes('comments') ? 'invalid-field' : ''}>
          Any additional comments or questions:
          <textarea
            name="comments"
            value={formData.comments}
            rows="4"
            onChange={handleChange}
            required
          />
        </label>
        <button type="submit">Submit Application</button>
      </form>
    </div>
  );
};

export default ApplicationForm;
