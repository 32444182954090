import React from "react";
import NorthWestIcon from '@mui/icons-material/NorthWest';
import SouthWestIcon from '@mui/icons-material/SouthWest';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import SouthEastIcon from '@mui/icons-material/SouthEast';
import { IconButton } from "@mui/material";
const Flagship = () =>{
    return(
        <div className="flagship" id="flagship">
            <div className="container">
                
                <div className="d-flex-lana">
                    <div className="d-flex column">
                        <div className="flagbox">
                            <p>Interpersonal Skills: Workshops focused on improving communication and relationship-building skills.
                            </p>
                        </div>
                        <div className="flagbox">
                        <p>Mentoring and Guidance: Programs that provide mentoring and guidance to help individuals reach their full potential.
                        </p>
                        </div>
                        <div className="flagbox">
                        <p>Leadership Effectiveness: Hands-on learning experiences and simulations to develop leadership skills.
                        </p>
                        </div>
                    </div>

                    <div className="flagIcon">
                    <IconButton aria-label="delete" className="icontopleft" >
                    <NorthWestIcon  style={{ fontSize: 40 }} />
                    </IconButton>
                    
                    <IconButton className="icontopright">
                    <NorthEastIcon style={{ fontSize: 40 }} />
                    </IconButton>
                    <h5> Our Flagship Offerings</h5>
                    <IconButton className="iconbottomleft">
                    <SouthWestIcon style={{ fontSize: 40 }} />
                    </IconButton>
                   <IconButton className="iconbottomright">
                   <SouthEastIcon style={{ fontSize: 40 }} />
                   </IconButton>
                    </div>

                    <div className="d-flex column">
                        <div className="flagbox">
                        <p>Team Effectiveness: Activities designed to enhance team bonding, collaboration, and communication.
                        </p>
                        </div>
                        <div className="flagbox">
                        <p>Sustainable Living: Education on practical ways to live sustainably and reduce ecological footprints.
                        </p>
                        </div>
                        <div className="flagbox">
                        <p>Community Projects: Engagement in local environmental and community projects to foster a sense of shared responsibility and community-driven mission.
                        </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Flagship