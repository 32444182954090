import React from 'react';
import './styles/RefundPolicyPage.css'; // Import the CSS file for styling

const RefundPolicyPage = () => (
  <section className="policy-page" id="policy-page">
    <div className="container">
      <h2>Refund Policy</h2>
      <p>Thank you for enrolling in our programs. We ensure that our users have a rewarding experience while they discover, assess, and enroll in our programs.</p>
      <p>As with any online purchase, there are terms and conditions that govern our Refund Policy. When you buy a program from us, you agree to our Privacy Policy, Terms of Use, and Refund Policy.</p>
      <h3>To qualify for a refund you must:</h3>
      <ul>
        <li>Send your cancellation request through email at <a href="mailto:refund@laana.com">refund@laana.com</a>.</li>
        <li>Cancellation shall only be accepted if informed at least 10 days prior to the start date of the program.</li>
        <li>Refunds or any duplicate payment shall be made within 10-12 working days from the date of cancellation request.</li>
        <li>Any refund request failing to meet the above conditions shall not be accepted and no refund will be provided.</li>
      </ul>
      <p>OGA INTRADE reserves the right to revise this policy without any prior notice.</p>
      <p><em>Last Updated on 10th April 2024</em></p>
    </div>
  </section>
);

export default RefundPolicyPage;
