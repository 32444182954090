// CommunitySection.jsx
import React from 'react';
import './styles/CommunitySection.css'
import comimg from '../images/comunityimg.jpg'
const CommunitySection = () => (
  <section className="community" id="community">
    <div className="container">
    <div className='community_imgdv'>
      <div className='imgdv'>
        <div className='comunity_img'></div>
      {/* <img src={comimg} /> */}
      </div>
  <div className="imgdv_content">
  <h2 className="comun_head">Our <br /> Community</h2>
    <p >Our global network encompasses a wide range of talents, including skilled engineers, dedicated consultants, and dynamic partners who contribute to our success.With a presence in 5+ countries, we embrace the richness of various cultures, perspectives, and experiences. This diversity not only fuels creativity but also strengthens our ability to tackle challenges and deliver innovative solutions. Our collaborative spirit knows no geographical boundaries as we leverage the collective knowledge of our global team to drive excellence in every endeavor.
    As we continue to grow and expand our horizons, our commitment to fostering a global community remains unwavering. Together, we create an environment where talents converge, ideas flourish, and meaningful connections are formed. Join us in shaping the future, transcending borders, and making a positive impact on a global scale. </p>
  </div>
    </div>
    </div>
    <div className="logo-container">
      {/* <div className="logo1"></div>
      <div className="logo2"></div> */}
      {/* <div className="logo3"></div> */}
      {/* <div className="logo4"></div>
      <div className="logo5"></div> */}
    </div>
  </section>
);

export default CommunitySection;