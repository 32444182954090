import React from 'react';
import HomeButton from '../HomeButton';
import './Project.css'; // Import the styles
import ApplicationForm from './ApplicationForm'; // Import the ApplicationForm component

const Project2 = () => {
  

  return (
    <>
      <HomeButton/>
      <div className="project2-bigpicture"> </div>
      <div className="project-container">
        
        <div className="project-details-cards">
          <h1>Digital Horizon: A 15-Day Digital Literacy Program for the Underprivileged</h1>
          <p>Date: TBD</p>
          <p>Details: A 15 day digital horizon program will give an opportunity to students and individuals to empower people from humble background by fostering essential digital skills thus giving them an access to oppoprtunities in the digital age. Participants will teach them fundamental digital literacy concepts and practical skills to navigate the online world confidently. Interactions with experienced engineers and technologists will enable you to guide a group of underprivileged individuals, providing you with the understanding of how to effectively impart knowledge to the masses. </p>
          <p>Duration: 2 weeks</p>
          <p>Learning Outcomes: Engaging in this 2-week program will empower students to gain vital digital literacy skills, ranging from computer operations to internet safety and effective digital communication. Additionally, students will have the opportunity to share their newfound knowledge with underprivileged communities, making a meaningful impact.<strong> Certificates and Letters of Recomendations provided to all participants who have successfully completed the program</strong></p>
          <p>Knowledge Partners: TBD</p>
          <p>Batch Size: 5-10 students</p>
          <p>Cost: 15000 Rupees</p>
          <p>Location: Delhi NCR</p>
                      
<div class="program-audiences">
<h2>Who Should Do This Program:</h2>
<br></br>
    <div class="audience-category">
        <h4>1. Students:</h4>
        <ul>
            <li>Aspiring for careers in technology social sector, media, administrative services, etc.</li>
            <li>Seeking to build a robust profile for studies abroad or future career opportunities.</li>
            <li>Interested in integrating social work into their summer vacation or as part of their curriculum.</li>
            <li>Wishing to make meaningful use of their summer vacation by contributing to a cause and learning new skills.</li>
        </ul>
    </div>
    <div class="audience-category">
        <h4>2. Educators and Mentors:</h4>
        <ul>
            <li>Looking to broaden teaching skills beyond the traditional classroom settings.</li>
            <li>Eager to engage with and impact students from diverse backgrounds and environments.</li>
            <li>Interested in developing new teaching methodologies and contributing to societal change.</li>
        </ul>
    </div>
    <div class="audience-category">
        <h4>3. Professionals Seeking Personal Growth:</h4>
        <ul>
            <li>Aiming to enhance leadership, communication, and interpersonal skills.</li>
            <li>Looking to contribute to a meaningful cause while undergoing personal development.</li>
            <li>Interested in exploring new areas of growth outside the conventional professional environment.</li>
        </ul>
    </div>
    <div class="audience-category">
        <h4>4. Corporate Teams Interested in CSR Initiatives:</h4>
        <ul>
            <li>Companies and organizations aiming to engage their employees in meaningful CSR activities.</li>
            <li>Teams looking for team-building exercises that offer tangible impact and community engagement.</li>
            <li>Corporate groups wanting to enhance their public image and contribute positively to society.</li>
        </ul>
    </div>
</div>

                    <h2>Delivery Method:</h2>
                    <ul>
                        <li>Daily interactive sessions with teachers and facilitators.</li>
                        <li>Hands-on activities and group projects.</li>
                        <li>Collaborative projects and group discussions.</li>
                        <li>Outdoor activities for comprehensive learning.</li>
                    </ul>

                    <h2>Evaluation:</h2>
                    <p>Participants will be assessed through active participation, projects, and a final showcase of their learnings.</p>

                    <h2>Certification:</h2>
                    <p>Participants who actively engage in the program will receive a certificate of completion and a Letter of recommendation to recognize their efforts.</p>

                    {/* Application Form */}
                    
          {/* Week Details Table */}
          <h2>Program Breakdown</h2>
          <table>
            <thead>
              <tr>
                <th>Day</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Day 1-2 </td>
                <td>Orientation & Introduction to Digital Literacy : <p>Welcome and Program Overview</p><p>Guest speakers/experts on the Significance of Digital Literacy</p></td>
              </tr>
              <tr>
                <td>Day 3-4</td>
                <td>Project Preparation and Implementation Planning: <p>Understanding the Project Scope</p> <p>Planning and Implementation Strategy Sessions</p>  </td>
              </tr>
              <tr>
                <td>Day 5-7</td>
                <td> Basic Digital Literacy Concepts <p>Introduction to Computer Hardware and Software</p> <p>Basic Computer Operations and File Management</p> </td>
              </tr>
              <tr>
                <td>Day 8-10</td>
                <td> Internet Navigation and Safe Practices <p> Navigating the Internet Safely and Efficiently </p> <p> Understanding Web Browsers and Search Engines</p>  </td>
              </tr>
              <tr>
                <td>Day 11-12</td>
                <td>Email Management and Digital Communication  <p> Creating and Managing Email Accounts </p> <p> Effective Communication in the Digital Environment </p></td>
              </tr>
              <tr>
                <td>Day 13</td>
                <td>Email Management and Digital Communication  <p> Review of On-Ground Teaching and Awareness </p> <p> Initial Discussions on Project Report Preparation </p></td>
              </tr>
              <tr>
                <td>Day 14</td>
                <td> Finalize Project Reports <p> Refine and Complete Project Reports </p> <p> Group Presentations and Feedback </p></td>
              </tr>
              <tr>
                <td>Day 15</td>
                <td> Award Ceremony <p> Evaluation of Project Reports </p> <p> Recognition and Awards for Best Projects </p></td>
              </tr>

            </tbody>
          </table>
        </div>
        
        {/* Application Form */}
        <ApplicationForm projectName="Digital Horizon"/>
      </div>
    </>
  );
};

export default Project2;
