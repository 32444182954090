import React from 'react';
import HomeButton from '../HomeButton';
import './Project.css'; // Import the styles
import ApplicationForm from './ApplicationForm'; // Import the ApplicationForm component

const Project4 = () => {
  

  return (
    <>
      <HomeButton/>
      <div className="project4-bigpicture"> </div>
      <div className="project-container">
        
        <div className="project-details-cards">
          <h1>GreenWave: A 7-Day Waste Management Mobilization Campaign</h1>
          <p>Date: TBD</p>
          <p>Details: GreenWave is a dynamic week-long initiative, redefining traditional awareness campaigns by incorporating art, street plays, dance, and advanced communication skills in collaboration with local authorities. Beyond education, this program actively engages volunteers in championing responsible waste management practices across communities in India. Street plays and dance serve as impactful tools for public awareness, creating a vibrant and memorable experience. The campaign also hones communication skills through strategic collaborations with local authorities, empowering volunteers to effectively convey the importance of sustainable living and waste reduction. GreenWave transforms volunteers into passionate advocates for a greener and more sustainable future.  </p>
          <p>Duration: 7 days</p>
          <p>Learning Outcomes: GreenWave, a week-long initiative, goes beyond educating volunteers on responsible waste management. Throughout the program, participants develop valuable skills applicable in various contexts. From honing analytical abilities during community surveys to refining problem-solving skills in segregation workshops, volunteers gain practical insights. Engaging in street plays and public awareness enhances communication skills, while active participation in clean-up drives demonstrates a commitment to community service. Collaborating with local authorities showcases advocacy and leadership, culminating in a closing event that emphasizes planning and coordination abilities. GreenWave equips volunteers with a unique skill set, contributing to their personal and professional growth. <strong>Certificates and Letters of Recomendations provided to all participants who have successfully completed the program</strong></p>
          <p>Knowledge Partners: TBD</p>
          <p>Batch Size: 5-10 students</p>
          <p>Cost: 10000 Rupees</p>
          <p>Location: Delhi NCR</p>
                      
<div class="program-audiences">
<h2>Who Should Do This Program:</h2>
<br></br>
    <div class="audience-category">
        <h4>1. Students:</h4>
        <ul>
            <li>Aspiring for careers in education, public policy, social sector, politics, or administrative services.</li>
            <li>Seeking to build a robust profile for studies abroad or future career opportunities.</li>
            <li>Interested in integrating social work into their summer vacation or as part of their curriculum.</li>
            <li>Wishing to make meaningful use of their summer vacation by contributing to a cause and learning new skills.</li>
        </ul>
    </div>
    <div class="audience-category">
        <h4>2. Educators and Mentors:</h4>
        <ul>
            <li>Looking to broaden teaching skills beyond the traditional classroom settings.</li>
            <li>Eager to engage with and impact students from diverse backgrounds and environments.</li>
            <li>Interested in developing new teaching methodologies and contributing to societal change.</li>
        </ul>
    </div>
    <div class="audience-category">
        <h4>3. Professionals Seeking Personal Growth:</h4>
        <ul>
            <li>Aiming to enhance leadership, communication, and interpersonal skills.</li>
            <li>Looking to contribute to a meaningful cause while undergoing personal development.</li>
            <li>Interested in exploring new areas of growth outside the conventional professional environment.</li>
        </ul>
    </div>
    <div class="audience-category">
        <h4>4. Corporate Teams Interested in CSR Initiatives:</h4>
        <ul>
            <li>Companies and organizations aiming to engage their employees in meaningful CSR activities.</li>
            <li>Teams looking for team-building exercises that offer tangible impact and community engagement.</li>
            <li>Corporate groups wanting to enhance their public image and contribute positively to society.</li>
        </ul>
    </div>
</div>

                    <h2>Delivery Method:</h2>
                    <ul>
                        <li>Daily interactive sessions with teachers and facilitators.</li>
                        <li>Hands-on activities and group projects.</li>
                        <li>Collaborative projects and group discussions.</li>
                        <li>Outdoor activities for comprehensive learning.</li>
                    </ul>

                    <h2>Evaluation:</h2>
                    <p>Participants will be assessed through active participation, projects, and a final showcase of their learnings.</p>

                    <h2>Certification:</h2>
                    <p>Participants who actively engage in the program will receive a certificate of completion and a Letter of recommendation to recognize their efforts.</p>

                    {/* Application Form */}
                    
          {/* Week Details Table */}
          {/* <h2>Program Breakdown</h2>
          <table>
            <thead>
              <tr>
                <th>Day</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Day 1 </td>
                <td>Orientaion and Introduction <p>Overview of the waste management scenario in India</p><p>Training on the importance of responsible waste disposal</p></td>
              </tr>
              <tr>
                <td>Day 2</td>
                <td>Community Surveys and Needs Assessment<p>Conducting surveys to understand local waste management practices</p> <p>Identifying specific needs and challenges in each community</p>  </td>
              </tr>
              <tr>
                <td>Day 3</td>
                <td> Workshops on Segregation and Recycling <p>Conducting workshops on proper waste segregation</p> <p>Demonstrating the importance and methods of recycling</p> <p>Creating proposals for local authorities</p></td>
              </tr>
              <tr>
                <td>Day 4</td>
                <td> Street Plays and Public Awareness <p> Organizing street plays to convey waste management messages</p> <p> Distributing informative pamphlets and creating awareness in public spaces </p>  </td>
              </tr> 
              <tr>
                <td>Day 5</td>
                <td> Clean-Up Drives and Beautification Projects<p> Participating in local clean-up drives </p> <p> Engaging in beautification projects using recycled materials </p></td>
              </tr>
              <tr>
                <td>Day 6</td>
                <td> Collaboration with Local Authorities<p> Meeting with local municipal officials </p> <p> Discussing and advocating for improved waste disposal infrastructure using project proposals </p></td>
              </tr>
              <tr>
                <td>Day 7</td>
                <td> Culmination Event and Pledge Ceremony <p> Hosting a closing event to celebrate the week's efforts </p> <p> Encouraging volunteers and community members to take a pledge for responsible waste managements </p></td>
              </tr>


            </tbody>
          </table> */}
        </div>
        
        {/* Application Form */}
        <ApplicationForm projectName="Green Wave"/>
      </div>
    </>
  );
};

export default Project4;
