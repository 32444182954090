// WhoWeAreForSection.jsx

import React from 'react';
import { Link as ScrollLink } from 'react-scroll';
import './styles/WhoWeAreFor.css'; // Ensure you have the corresponding CSS file
import './styles/SectionStyles.css'
// import imgs from '../images/squad.jpg'
const WhoWeAreForSection = () => (
  <section className="who-we-are-for" id="who-we-are-for">
    <div className="container">
      <div className='squd_width'>
      <div className="tx-50">
        <h2 className="antfont">Squad <br /> Goals</h2>
        <p>Experience - Empower - Excel</p>
      </div>
      {/* <div className="image-content-whowearefor"></div> */}
      <div className='squad_dv'>
      <div className='squad_img'></div>
      <p className="section-description whowe_Head">
      Unlock Potential Through Experiential Learning: Empowering Everyone to Achieve Excellence. Passionate students and skill seekers, unite! Dive into our Experiential Learning Programs, where every moment is a chance to learn something new. Join us in creating a better future by working with communities and organizations that need your services. This is where your skills meet endless possibilities!
       </p>
   
      </div>
      </div>
       </div>
  </section>
);

export default WhoWeAreForSection;
