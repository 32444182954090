// Footer.jsx
import React from 'react';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, Stack } from '@mui/material';
// import { Link } from 'react-router-dom';
// import { SOCIAL_LINKS } from './const';

const Footer = () => (
  <footer className="footer-section" style={{width:'100%', float:'left'}}>
    <div className="container">
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{width:'100%', padding:'12px 0'}}>
      <p>&copy; 2024 LaaNaa. All rights reserved.</p>
      {/* <Box sx={{display:'flex', columnGap:'2px'}}>
        {
          SOCIAL_LINKS.map((items)=>{
            return(
              <>
                <Link to={`${items.link}`} target='_blank'>{items.icon}</Link>
              </>
            )
          })
        }
        
      </Box> */}
      </Stack>
    </div>
  </footer>
);

export default Footer;