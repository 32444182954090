import React from 'react';


const SubmittedPageSuccess = () => {
  return (
    <div className="submitted-page-container">
      <h2 className="success-heading">Application Submitted Successfully!</h2>
      <p className="success-message">
        Thank you for applying. We'll get back to you soon.
      </p>
      {/* Add any additional content for the submitted page */}
    </div>
  );
};

export default SubmittedPageSuccess;
