import React from 'react';
import HomeButton from '../HomeButton';
import './Project.css'; // Import the styles
import ApplicationForm from './ApplicationForm'; // Import the ApplicationForm component

const Project1 = () => {


  return (
    <>
      <HomeButton/>
      <div className="project1-bigpicture"> </div>
      <div className="project-container">
        
        <div className="project-details-cards">
          <h1>Financial Literacy</h1>
          <p>Date: TBD</p>
          <p>Details: The primary goal of this initiative is to empower individuals from underprivileged communities with essential financial literacy skills, enabling them to make informed decisions, manage their finances effectively, and work towards financial stability. In this initiative, we are seeking individuals interested in working closely with experts in financial literacy and sharing all their gained knowledge in a structured manner with our beneficiaries. Our focus is on training individuals in rural and small communities, raising awareness about financial literacy. Participants will collaborate in groups of five students, with each student closely partnered with two others.</p>
          <p>Duration: 4 weeks</p>
          <p>Learning Outcomes: Participants gain the opportunity to receive valuable experience, along with networking opportunities with professionals in the field of finance. This additional benefit allows volunteers to expand their professional connections, engage with experts, and potentially open doors to further career development within the financial industry. <strong>Certificates and Letters of Recomendations provided to all participants who have successfully completed the program</strong></p>
          <p>Knowledge Partners: TBD</p>
          <p>Batch Size: 5-10 students</p>
          <p>Cost: 25000 Rupees</p>
          <p>Location: Delhi NCR</p>
                      
<div class="program-audiences">
<h2>Who Should Do This Program:</h2>
<br></br>
    <div class="audience-category">
        <h4>1. Students:</h4>
        <ul>
            <li>Aspiring for careers in finance.</li>
            <li>Seeking to build a robust profile for studies abroad or future career opportunities.</li>
            <li>Interested in integrating social work into their summer vacation or as part of their curriculum.</li>
            <li>Wishing to make meaningful use of their summer vacation by contributing to a cause and learning new skills.</li>
        </ul>
    </div>
    <div class="audience-category">
        <h4>2. Educators and Mentors:</h4>
        <ul>
            <li>Looking to broaden teaching skills beyond the traditional classroom settings.</li>
            <li>Eager to engage with and impact students from diverse backgrounds and environments.</li>
            <li>Interested in developing new teaching methodologies and contributing to societal change.</li>
        </ul>
    </div>
    <div class="audience-category">
        <h4>3. Professionals Seeking Personal Growth:</h4>
        <ul>
            <li>Aiming to enhance leadership, communication, and interpersonal skills.</li>
            <li>Looking to contribute to a meaningful cause while undergoing personal development.</li>
            <li>Interested in exploring new areas of growth outside the conventional professional environment.</li>
        </ul>
    </div>
    <div class="audience-category">
        <h4>4. Corporate Teams Interested in CSR Initiatives:</h4>
        <ul>
            <li>Companies and organizations aiming to engage their employees in meaningful CSR activities.</li>
            <li>Teams looking for team-building exercises that offer tangible impact and community engagement.</li>
            <li>Corporate groups wanting to enhance their public image and contribute positively to society.</li>
        </ul>
    </div>
</div>

                    <h2>Delivery Method:</h2>
                    <ul>
                        <li>Daily interactive sessions with teachers and facilitators.</li>
                        <li>Hands-on activities and group projects.</li>
                        <li>Collaborative projects and group discussions.</li>
                        <li>Outdoor activities for comprehensive learning.</li>
                    </ul>

                    <h2>Evaluation:</h2>
                    <p>Participants will be assessed through active participation, projects, and a final showcase of their learnings.</p>

                    <h2>Certification:</h2>
                    <p>Participants who actively engage in the program will receive a certificate of completion and a Letter of recommendation to recognize their efforts.</p>

                    {/* Application Form */}
                    
          {/* Week Details Table */}
          {/* <h2>Program Breakdown</h2>
          <table>
            <thead>
              <tr>
                <th>Day</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Day 1-3 </td>
                <td>Introduction to financial literacy. Introduce participants to the program through an orientation session., Engage participants in sessions with speakers, providing insights into the program's objectives and expectation</td>
              </tr>
              <tr>
                <td>Day 4-8</td>
                <td>Facilitate activities to enhance participants' understanding of the project. Outline the scope of the project, detailing what they will teach and how they plan to implement it.</td>
              </tr>
              <tr>
                <td>Day 9-24</td>
                <td>Participants work directly with beneficiaries to implement and execute their projects. Encourage active collaboration and hands-on experience to deepen their understanding of the beneficiaries' needs.</td>
              </tr>
              <tr>
                <td>Day 25-29</td>
                <td>Participants reflect on their experiences and learning during the program. Guide participants in preparing comprehensive reports summarizing their project outcomes, challenges, and key takeaways</td>
              </tr>
              <tr>
                <td>Day 30</td>
                <td>Evaluate the reports and award the best one. Provide the winner with the opportunity to choose from available course options, further enhancing their skills and knowledge.</td>
              </tr>
            
            </tbody>
          </table> */}
        </div>
        
        {/* Application Form */}
        <ApplicationForm projectName="Financial Literacy Program"/>
      </div>
    </>
  );
};

export default Project1;
