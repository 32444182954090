// Sidebar.jsx
import React from 'react';
import { Link as ScrollLink } from 'react-scroll';
import './Sidebar.css';

const Sidebar = ({ openNav, isSidebarOpen, closeNav, handleOnClick }) => (
  <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`} onMouseLeave={closeNav}>
    <button className="sidebar-toggle" onClick={isSidebarOpen ? closeNav : openNav}>
      <span>&#9776;</span>
    </button>
    <ul>
      <li>
        <ScrollLink to="hero-section" smooth={true} duration={500} onClick={() => handleOnClick(false)}>
          Home
        </ScrollLink>
      </li>
      <li>
        <ScrollLink to="who-we-are-for" smooth={true} duration={500} onClick={() => handleOnClick(false)}>
          Our goals
        </ScrollLink>
      </li>
      <li>
        <ScrollLink to="who-we-are" smooth={true} duration={500} onClick={() => handleOnClick(false)}>
          Team Laanaa
        </ScrollLink>
      </li>
      <li>
        <ScrollLink to="projects" smooth={true} duration={500} onClick={() => handleOnClick(false)}>
          Projects
        </ScrollLink>
      </li>
      {/* <li>
        <ScrollLink to="testimonial-section" smooth={true} duration={500}>
          Testimonials
        </ScrollLink>
      </li> */}
      <li>
        <ScrollLink to="community" smooth={true} duration={500} onClick={() => handleOnClick(false)}>
          Our Community
        </ScrollLink>
      </li>
      <li>
        <ScrollLink to="flagship" smooth={true} duration={500} onClick={() => handleOnClick(false)}>
          Our Flagship Offering
        </ScrollLink>
      </li>
      <li>
        <ScrollLink to="whyus" smooth={true} duration={500} onClick={() => handleOnClick(false)}>
          Why Us
        </ScrollLink>
      </li>
      <li>
        <ScrollLink to="ourapproach" smooth={true} duration={500} onClick={() => handleOnClick(false)}>
          Our Approach
        </ScrollLink>
      </li>
      <li>
        <ScrollLink to="cta-section" smooth={true} duration={500} onClick={() => handleOnClick(false)}>
          Contact Us
        </ScrollLink>
      </li>
    </ul>
  </div>
);

export default Sidebar;
