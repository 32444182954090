// ProjectsSection.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import './styles/ProjectsSection.css'; // Ensure the path is correct based on your file structure
import StarComponent from './StarComponent'; // Adjust the path as necessary

const ProjectsSection = () =>{

  const redirectToExternalURL = () => {
    window.location.href = 'https://docs.google.com/document/d/1AXlkWigjkeHAVcD0z1W1JwARXenib-CsLaQjFOY87V0/pub';
  };

  return(
    <section id="projects" className="projects">
    <div className="container">
      <h2 className="exp-title">Experiential Learning Programs</h2>

      <div className="project-card-container">
      {/* Example Project Card 1 */}
      <div className="project-card">
        <div className="project-image project-image4"></div>
<div className='project_flex'>
<div className="project-details">
          <h3>
            GreenWave 
          </h3>
          <p>Project Dates: Program Starting From October 9th,2024</p>
          <p>Dive into the future of Smart Cities, Sustainable Fashion, and Waste Management, gaining the skills to lead in tomorrow's green industries.</p>
         
        </div>
       <div>
       {/* <a href="/https://docs.google.com/forms/d/e/1FAIpQLSd_x_8G70lDx3LDpRFD2nKr69Lw1O-0F6ls0rFA98t44fQEDQ/viewform">
            <button className="view-details-button">Open</button>
          </a> */}
          {/* <button className="view-details-button" style={{background:'green'}} onClick={redirectToExternalURL}>Open</button> */}
          <Link className="view-details-button" style={{background:'green', textDecoration:'none'}}  to={'/greenwaveRegistration'}>Open</Link>
       <p style={{color: 'green'}}><strong>Registration Open</strong></p>
       </div>
</div>
      </div>
      
      <div className="project-card">
        <div className="project-image project-image1"></div>
       <div className='project_flex'>
       <div className="project-details">
          <h3>
            Financial Literacy 
          </h3>
          <p>Project Dates:TBD</p>
          <p>Empower communities in Delhi NCR with financial literacy in just four weeks. Gain valuable experience and networking opportunities for your career growth.</p>
        </div>
       <div>
       <Link to="/project1">
            <button className="view-details-button">Opening Soon</button>
          </Link>
        <p style={{color: 'red'}}><strong></strong></p>
       </div>
       </div>
      </div>

      <div className="project-card">
        <div className="project-image project-image2"></div>
       <div className='project_flex'>
       <div className="project-details">
          <h3>
            Digital Horizon 
          </h3>
          <p>Project Dates: TBD</p>
          <p>A dynamic 15-day program in Delhi NCR, designed to equip underprivileged individuals with fundamental digital skills, fostering empowerment and broadening access to digital opportunities.</p>
         
        </div>
        <div>
        <Link to="/project2">
            <button className="view-details-button">Opening Soon</button>
          </Link>
        <p style={{color: 'red'}}><strong></strong></p>
        </div>
       </div>
      </div>

      <div className="project-card">
        <div className="project-image project-image3"></div>
<div className='project_flex'>
<div className="project-details">
          <h3>
            Bright Minds 
          </h3>
          <p>Project Dates: TBD</p>
          <p>Join Bright Minds, a transformative 15-day volunteering program that empowers underprivileged school children in India through a holistic curriculum. Gain invaluable leadership, communication, and teaching skills while making a meaningful impact on young lives in Delhi NCR.</p>
          
        </div>
        <div>
        <Link to="/project3">
            <button className="view-details-button">Opening Soon</button>
          </Link>
        <p style={{color: 'red'}}><strong></strong></p>
        </div>
</div>
      </div>
      

      <div className="project-card">
        <div className="project-image project-image5"></div>
<div className='project_flex'>
<div className="project-details">
          <h3>
            SkillSprint 
          </h3>
          <p>Project Dates: TBD</p>
          <p>An Outreach Program for Volunteer-driven Skilling Awareness in India</p>
          
        </div>

        <div>
        <Link to="/project5">
            <button className="view-details-button" disabled>Opening Soon</button>
          </Link>
        <p style={{color: 'red'}}><strong></strong></p>
        </div>
</div>
      </div>
      <div className="project-card">
        <div className="project-image project-image6"></div>
<div className='project_flex'>
<div className="project-details">
          <h3>
            Cultural Canvas
          </h3>
          <p>Project Dates: TBD</p>
          <p>Cultural Canvas is a two-week program designed to provide participants with a rich and immersive experience in India's art and culture. This program combines heritage walks, visits to art museums, and meaningful engagement local artisans within the community to foster a deeper understanding and appreciation of India's diverse cultural heritage.</p>
         
        </div>
        <div>
        <Link to="/project6">
            <button className="view-details-button">Opening Soon</button>
          </Link>
          <p style={{color: 'red'}}><strong></strong></p>
        </div>
</div>
      </div>
     
    </div>

      
      </div>
      
    
  </section>
  )
};

export default ProjectsSection;
