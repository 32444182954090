import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

const HomeButton = () => (
  <Link to="/" className="home-button-link">
    <button className="home-button" aria-label="Home">
      <FontAwesomeIcon icon={faHome} />
    </button>
  </Link>
);

export default HomeButton;