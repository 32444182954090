import React from 'react';
import './styles/AboutUs.css'; // Ensure this file is updated with the new styles below
import HomeButton from './HomeButton';

const AboutUs = () => {
  return (
    <>
    <HomeButton/>
    <div className="about-us-container">


      <div className="team-section">
        <h2>Meet Our Team</h2>
        <div className="team-members">
          <div className="team-member">
            <div className="member-image anuj-image"></div>
            <h3>Anuj Bhatia</h3>
            <h5>Founder</h5>
            <p>Anuj, the founder of our organization, is an experienced professional with a degree in Computer Science from St. Stephen's and management training from IIM-C. He also attended NIFT, Delhi, to gain industry-relevant skills and knowledge, where he has been active for the past 25 years. Anuj is an avid reader dedicated to education and has significantly contributed to our skill development programs. In his free time, he enjoys 80s rock music, which both inspires and relaxes him. Anuj founded Laanaa with a vision to provide students with experiential learning opportunities.</p>
          </div>
          <div className="team-member">
            <div className="member-image abida-image"></div>
            <h3>Abida Nahid</h3>
            <h5>Board member-Marketing</h5>
            <p>A curious life explorer, Abida has navigated the world of films, television, advertising, media, online gaming world and Youtube. With a mind that thrives on creativity and a heart full of compassion, she find new meaning to life as an influencer, public speaker and an aspiring drummer.</p>
          </div>
          <div className="team-member">
            <div className="member-image Aadit-image"></div>
            <h3>Aadit</h3>
            <h5>Co-Founder, Laanaa</h5>
            <p>Aadit, a Computer Science graduate from the University of Massachusetts, is a forward-thinking individual with a passion for research, science, and technology. His analytical mindset drives his belief that these fields hold the key to solving many of the world’s challenges. Beyond his professional expertise, Aadit has represented his country as a competitive chess player, showcasing his strategic and sharp thinking.
            Currently based in California, Aadit works with Amazon Worldwide Services, where he plays a pivotal role in designing cutting-edge programs, particularly those focused on technology and AI. His innovative vision led to the creation of Laanaa, a platform dedicated to driving positive change through sustainable innovation.
              </p>
          </div>
          <div className="team-member">
            <div className="member-image jayantee-image"></div>
            <h3>Jayantee Chandrasekar</h3>
            <h5>Board Member-US-India Strategic Partner</h5>
            <p>An astute business development and PR professional with exceptional ability to forge long lasting relationships with a passion
            for tennis, travelling and networking Jay combines work with pleasure exploring new markets and devising innovative strategies.</p>
          </div>

          <div className="team-member">
            <div className="member-image sheilza-image"></div>
            <h3>Sheilza Bhatia</h3>
            <h5>Board Member</h5>
            <p>Sheilza is a dedicated social worker with over a decade of experience in empowering communities, particularly focusing on women's livelihoods. An avid reader and traveler, she loves exploring new opportunities. Committed to personal growth, Sheilza believes education never stops and is currently studying sustainability science. At LAANAA, she has been instrumental in developing impactful programs, driven by her dedication to social change and continuous learning.
          </p>
          </div>
          {/* Adjust for Prachi and Abida to be on the next row or adjust as needed */}
        </div>
        {/* <div className="team-members">
          
        </div> */}
      </div>

      {/* Include other sections like 'Our Core Values' as needed */}
      {/* <section className="our-values">
        <h2>Our Core Values</h2>
        <ul>
           <li>Empowerment: Enabling individuals to achieve their potential.</li>
           <li>Innovation: Finding creative solutions to complex challenges.</li>
           <li>Community: Building strong, supportive relationships.</li>
        </ul>
      </section> */}
    </div>
    </>
    
  );
};

export default AboutUs;



// import React from 'react';
// import './styles/AboutUs.css'; // Ensure this CSS file contains all required styles

// const AboutUs = () => {
//   return (
//     <div className="about-us-container">
//       <div className="header-section">
//         <h1>About LAANAA</h1>
//         <p>We are committed to empowering individuals through education and skill development.</p>
//       </div>

//       <div className="team-section">
//         <h2>Meet Our Team</h2>
//         <div className="team-members">
//           <div className="team-member">
//             <div className="member-image anuj-image"></div>
//             <h3>Anuj</h3>
//             <p>Anuj is a dedicated professional with a wealth of experience in the Fashion & Textile Sector...</p>
//           </div>
//           <div className="team-member">
//             <div className="member-image sheilza-image"></div>
//             <h3>Sheilza</h3>
//             <p>Sheilza is a dedicated social worker who has devoted the past decade to making a difference...</p>
//           </div>
//           <div className="team-member">
//             <div className="member-image Aadit-image"></div>
//             <h3>Aadit</h3>
//             <p>Aadit, a Computer Science graduate from the University of Massachusetts, is a forward-thinking...</p>
//           </div>
//           <div className="team-member">
//             <div className="member-image abida-image"></div>
//             <h3>Abida Nahid</h3>
//             <p>A curious life explorer, Abida has navigated the world of films, television, advertising...</p>
//           </div>
//         </div>
//       </div>

//       <section className="our-values">
//         <h2>Our Core Values</h2>
//         <ul>
//           <li>Empowerment: Enabling individuals to achieve their potential.</li>
//           <li>Innovation: Finding creative solutions to complex challenges.</li>
//           <li>Community: Building strong, supportive relationships.</li>
//         </ul>
//       </section>
//     </div>
//   );
// };

//export default AboutUs;
