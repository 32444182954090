
import React from "react";

const Whyus = () =>{
    return(
        <div className="whyus" id="whyus">
            <div className="container">
                <div className="why_flex">
                    <div className="lft_content">
                        <p>LaaNaa is committed to providing innovative and impactful learning experiences that give individuals and organizations a competitive edge. We focus on delivering programs that achieve specific educational and professional objectives, drive measurable outcomes, and foster behavioral changes that can be applied in academic, professional, and personal contexts. Our unique approach ensures that participants not only acquire new skills but also gain a deeper understanding of their potential.</p>
                        <div className="img_maindv">
                            <ul>
                                <li></li>
                                <li></li>
                                <li></li>
                            </ul>
                            <div className="img_chlddv"></div>
                        </div>
                    </div>
                    <div className="ryt_content">
                       <div className="rytchdv">
                       <p>
We assist in significantly strengthenin students in gaining a competitive edge. Our core focus is to deliver learning programs that achieve specific educational objectives and outcomes a that students can apply in their academic and personal lives.
</p>
                       </div>
<div className="whybtdv"><a href="" className="whybutton">Why Us</a></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Whyus