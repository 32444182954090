// WhoWeAreSection.jsx
import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you are using React Router for navigation
import './styles/WhoWeAre.css'; // Ensure you have the corresponding CSS file
import './styles/SectionStyles.css'
const WhoWeAreSection = () => (
  <section className="who-we-are lanateam_sec" id="who-we-are">
    <div className="container">
      <div className="section-content">
      <div className='lanateamimg'></div>
        <div className="text-content">
          <h2 className="section-title">Team Laanaa</h2>
          <p className="section-description tm_lana">We're a diverse team of socially conscious innovators with expertise in engineering, government, academia, and more. From India's remote Mewat to the vibrant landscapes of the United States and Europe, we've navigated it all. Let us guide you to success with our global insights and tailored skills.</p>
          <p className="section-description">
            <Link to="/about" className="learn-more-link">Learn more about us</Link>
          </p>
        </div>
        {/* <div className="image-content-whoweare"> </div> */}
        <div className='lanateamimg'></div>
      </div>
    </div>
  </section>
);

export default WhoWeAreSection;
